import styled from "@emotion/styled"
import backgroundImage from "./assets/background_min_2.png"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 0;
  &::before {
    content: " ";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`

export const WrapperContent = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  background-color: #282828EE;
`